import React, { useState, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import { MdLanguage } from "react-icons/md";
import en from "../../../../assets/en.svg";
import ru from "../../../../assets/ru.svg";
import ge from "../../../../assets/ge.svg";

const LanguagesComp = ({ i18n }) => {
  const [showMenu, setShowMenu] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const languageOptions = [
    { lang: "en", alt: "ru", imgSrc: en },
    { lang: "ru", alt: "ru", imgSrc: ru },
    { lang: "ge", alt: "ge", imgSrc: ge },
  ];

  const menu = (
    <ul className="bg-[#091D3E]">
      {languageOptions.map((option, index) => (
        <li key={index}>
          <button onClick={() => {changeLanguage(option.lang); setShowMenu(!showMenu)}}>
            <img className="w-8 mx-1" src={option.imgSrc} alt={option.alt} />
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <Fragment>
      <div className="relative">
        <button
          className="flex items-center gap-1 ant-dropdown-link"
          onClick={() => setShowMenu(!showMenu)}
        >
          <MdLanguage
            className="text-2xl text-[#43D5CB]
            mob:text-[22px]
            tab:text-sm"
          />
          <DownOutlined className="my-auto text-[#43D5CB]" />
        </button>
        {showMenu && <div className="absolute right-0 top-full">{menu}</div>}
      </div>
    </Fragment>
  );
};

export default withTranslation()(LanguagesComp);
