// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper";

import employee1 from "../../../../assets/EmployeesCarousel/employees1.jpg"
import employee2 from "../../../../assets/EmployeesCarousel/employees2.jpg"
import employee3 from "../../../../assets/EmployeesCarousel/employees3.jpg"
import employee4 from "../../../../assets/EmployeesCarousel/employees4.jpg"
import employee5 from "../../../../assets/EmployeesCarousel/employees5.jpg"
import employee6 from "../../../../assets/EmployeesCarousel/employees6.jpg"
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cube";
import { EffectCube } from "swiper";

const employeesImg = [ 
  { img: employee1, id: 1 },
  { img: employee2, id: 2 },
  { img: employee3, id: 3 },
  { img: employee4, id: 4 },
  { img: employee5, id: 5 },
  { img: employee6, id: 6 }
];

const EmployeesCarousel = () => {
  return (
    <>
      <div className="mob:hidden">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={25}
          slidesPerView={3}
          navigation={true}
          pagination={{ clickable: true }}
          loop={true}
        >
          {employeesImg.map((img) => (
            <SwiperSlide key={img.id}>
              <img
                className="rounded-md shadow-xl"
                src={img.img}
                alt="ReviewsImg"
                key={img.id}
              ></img>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden mob:block">
        <Swiper
          effect={"cube"}
          grabCursor={true}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          pagination={true}
          modules={[EffectCube, Pagination]}
          className="mySwiper"
        >
          {/* {reviewsImgs.map((img) => (
            <SwiperSlide key={img.id}>
              <img
                className="rounded-md shadow-lg m-4"
                src={img.img}
                alt="ReviewsImg"
                key={img.id}
              ></img>
            </SwiperSlide>
          ))} */}
          <SwiperSlide>
            <img src={employeesImg[0].img} alt="ReviewsImg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={employeesImg[1].img} alt="ReviewsImg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={employeesImg[2].img} alt="ReviewsImg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={employeesImg[3].img} alt="ReviewsImg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={employeesImg[4].img} alt="ReviewsImg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={employeesImg[5].img} alt="ReviewsImg" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default EmployeesCarousel;
