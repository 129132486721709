// import PromotionsComp from "./PromotionsComp/PromotionsComp";


// const promotionsList = [
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "AMAZING WHITE Premium (USA)-новейшая клиническая система⭐️⭐️⭐️Только в апреле всего 200 лари!",
//     img: prom1,
//     id: 1,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "AMAZING WHITE Premium (USA)-новейшая клиническая система⭐️⭐️⭐️Только в апреле всего 200 лари!",
//     img: prom2,
//     id: 2,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "AMAZING WHITE Premium (USA)-новейшая клиническая система⭐️⭐️⭐️Только в апреле всего 200 лари!",
//     img: prom3,
//     id: 3,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom4,
//     id: 4,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom5,
//     id: 5,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom6,
//     id: 6,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom7,
//     id: 7,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom8,
//     id: 8,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom9,
//     id: 9,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom10,
//     id: 10,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom11,
//     id: 11,
//   },
//   {
//     title: "Подготовь улыбку к лету!",
//     content:
//       "Скидка 10% на все стоматологические услуги всем членам Вашей семьи! Акция длится до 15.09.2021",
//     img: prom12,
//     id: 12,
//   },
// ];

const Promotions = () => {
  return (
    <div className="main_container text-center py-20 h-[900px]">
      <h1 className="text-red-700 font-bold text-2xl ">НАШИИ АКЦИИ (скоро...)</h1>
      {/* <div className="text-left w-3/4 mx-auto my-5">
        Текскт-заголовок для раздела "Акции" Текскт-заголовок для раздела
        "Акции" Текскт-заголовок для раздела "Акции" Текскт-заголовок для
        раздела "Акции"
      </div>
      <PromotionsComp promotionsList={promotionsList} /> */}
    </div>
  );
};

export default Promotions;
