import Ymaps from "../Ymaps/Ymaps";

const FooterFourthRow = () => {
  return (
    <div className="text-red-800">
      <Ymaps />
    </div>
  );
};

export default FooterFourthRow;
